<template>
  <v-menu offset-y v-bind="$attrs" v-model="visibleMenu">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        :color="buttonColorClass"
        :outlined="buttonOutlined"
        :text="buttonRecommendedTextStyle"
        :rounded="buttonRounded"
        :elevation="buttonElevation"
        :class="buttonRecommendedClass(spacingDirection.left)"
        :disabled="disabled"
      >
        <!-- icon -->
        <v-icon
          v-show="hasSlot('iconName') || iconName"
          :class="recommendedSpacingClass(spacingDirection.right)"
        >
          <slot name="iconName">{{ iconName }}</slot>
        </v-icon>

        <!-- Command Name -->
        <span v-if="visibleOnSmAndUp">
          <slot></slot>
        </span>

        <!-- Drop-down menu indicator -->
        <v-icon>
          <slot name="iconMenuDownName">
            {{ iconMenuDown }}
          </slot>
        </v-icon>
      </v-btn>
    </template>
    <v-list dense>
      <slot name="commands"></slot>
    </v-list>
  </v-menu>
</template>

<script>
import { iconMenuDown } from "@/design/icon/iconConst";

// mixins
import { menuItemSettingsMixin } from "@/mixins/shared/base/settings/menuItemSettingsMixin";
import { buttonSettingsMixin } from "@/mixins/shared/base/settings/buttonSettingsMixin";
import { reactiveMixin } from "@/mixins/shared/reactive/reactiveMixin";
import { slottableMixin } from "@/mixins/shared/slottable/slottableMixin";

export default {
  name: "BaseDropDownMenuButton",
  inheritAttrs: false,
  mixins: [
    menuItemSettingsMixin,
    buttonSettingsMixin,
    reactiveMixin,
    slottableMixin
  ],
  data() {
    return {
      visibleMenu: false,
      iconMenuDown: iconMenuDown
    };
  },
  props: {
    iconName: {
      type: String,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    /**
     * Exposes ability to close opened menu externally
     */
    closeMenu() {
      this.visibleMenu = false;
    }
  }
};
</script>
